export const fetchCmsMetaObjects = async (cmsType = "", storeId) => {
  const endpoint = `${
    process.env.REACT_APP_FS_SERVER_BASE_URL || "http://localhost:3000"
  }/v0/api/shopifyCms/getAllCmsDataMetaObjects/${cmsType}${
    storeId ? `?storeId=${storeId}` : ""
  }`;
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  const result = await fetch(endpoint, requestOptions).then(
    async (response) => {
      const responseRes = JSON.parse(await response?.text());
      return responseRes;
      //return responseRes.map(collectionObj =>barListType==='featuredCollection'?collectionObj:collectionObj?.handle);
    }
  );
  return result;
};
