"use client";
import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }
  return "unknown";
};

const ShareIcons = ({ json }) => {
  const [copied, setCopied] = useState(false);

  const tractEvent = ({ event, data }) => {
    try {
      window?.analytics?.track(event, data);
      window?.analytics?.mixpanelTrack(event, data);
      window?.analytics?.moeTrack(event, data);
    } catch (error) {
      console.error(error?.message);
    }
  };

  useEffect(() => {
    tractEvent({ event: "blog_view", data: { url: window.location.href } });
  }, []);

  const onLinkClick = ({ iconName }) => {
    tractEvent({
      event: "blog_share_click",
      data: { url: window.location.href, target: iconName },
    });
    if (iconName?.includes("facebook")) {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
        "_blank"
      );
    } else if (iconName?.includes("x")) {
      window.open(
        `https://twitter.com/intent/tweet?text=Check%20this%20out!&url=${window.location.href}`,
        "_blank"
      );
    } else if (iconName?.includes("pinterest")) {
      window.open(
        `https://www.pinterest.com/pin/create/button/?url=${window.location.href}`,
        "_blank"
      );
    } else if (iconName?.includes("share")) {
      if (getMobileOperatingSystem() === "iOS") {
        navigator?.share({
          url: window.location.href,
        });
      } else if (getMobileOperatingSystem() === "Android") {
        navigator?.share({
          url: window.location.href,
        });
      } else {
        navigator.clipboard.writeText(window.location.href);
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      }
    }
  };

  return (
    <>
      <span>
        <Button
          sx={{
            border: "none",
            backgroundColor: "transparent",
            padding: "0",
            margin: "0",
            outline: "none",
            marginRight: "10px",
            minWidth: "0px",
          }}
          onClick={() => onLinkClick({ iconName: "facebook" })}
        >
          <img
            src="https://img.icons8.com/?size=20&amp;id=8818&amp;format=png&amp;color=000000"
            alt="icon-facebook"
          />
        </Button>
      </span>
      <span>
        <Button
          sx={{
            border: "none",
            backgroundColor: "transparent",
            padding: "0",
            margin: "0",
            outline: "none",
            marginRight: "10px",
            minWidth: "0px",
          }}
          onClick={() => onLinkClick({ iconName: "x" })}
        >
          <img
            src="https://img.icons8.com/?size=20&amp;id=phOKFKYpe00C&amp;format=png&amp;color=000000"
            alt="icon-x"
          />
        </Button>
      </span>
      <span>
        <Button
          sx={{
            border: "none",
            backgroundColor: "transparent",
            padding: "0",
            margin: "0",
            outline: "none",
            marginRight: "10px",
            minWidth: "0px",
          }}
          onClick={() => onLinkClick({ iconName: "pinterest" })}
        >
          <img
            src="https://img.icons8.com/?size=20&amp;id=11101&amp;format=png&amp;color=000000"
            alt="icon-pinterest"
          />
        </Button>
      </span>
      <span>
        <Button
          sx={{
            border: "none",
            backgroundColor: "transparent",
            padding: "0",
            margin: "0",
            outline: "none",
            minWidth: "0px",
          }}
          onClick={() => onLinkClick({ iconName: "share" })}
        >
          <Tooltip
            title={
              <Typography sx={{ fontSize: "16px", px: 2, py: 1 }}>
                Copied
              </Typography>
            }
            open={copied ? true : false}
            placement="top"
          >
            <img
              src="https://img.icons8.com/?size=20&amp;id=7701&amp;format=png&amp;color=000000"
              alt="icon-share"
            />
          </Tooltip>
        </Button>
      </span>
    </>
  );
};

export default ShareIcons;
