import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import Header from "../../layout/MainLayout/Header";
import { Box, Button, CircularProgress } from "@mui/material";
import { fetchCmsMetaObjects } from "../shopifyCms/fetchCmsMetaObjects";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import JsonEditor from "../../components/JsonEditor";

const cmsDataShow = [
  "AnnouncementBar",
  "NavBar",
  "MainSlidingBanner",
  "CollectionGrid",
  "Testimonials",
  "Banner",
  "BrandingSection",
  "BlogSection",
  "CollectionArray",
  "search",
  "pdpDiscount",
  "westernWear",
  "BannerArray",
  "SaleChanges"
];

const updateShopifyCms = async (data, storeId) => {
  const endpoint = `${
    process.env.REACT_APP_FS_SERVER_BASE_URL || "http://localhost:3000"
  }/v0/api/shopifyCms/metaobject-json-update?storeId=${storeId}`;
  const requestOptions = {
    method: "PATCH",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
    },
    body: data,
  };
  const result = await fetch(endpoint, requestOptions).then(
    async (response) => {
      const responseRes = JSON.parse(await response?.text());
      return { ...responseRes, status: response.status };
    }
  );
  return result;
};

const CmsJson = () => {
  const cookies = new Cookies();

  const [showCms, setShowCms] = useState({
    name: null,
    data: null,
    dataOrder: null,
  });
  const [isChanged, setIsChanged] = useState(false);
  const [cms, setCms] = useState([]);
  const [cmsData, setCmsData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [cmsDataFeaturedCollection, setCmsDataFeaturedCollection] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [apiRes, setApiRes] = useState({});
  const [changedData, setChangedData] = useState(null);
  const [changedDataOrder, setChangedDataOrder] = useState(null);
  const [changedCollections, setChangedCollections] = useState(null);
  const [changedCollectionOrder, setChangedCollectionOrder] = useState(null);
  const [errorMessage, setErrorMessage] = useState({});

  const navigate = useNavigate();

  const isloggedin = async () => {
    let accessToken = await cookies.get("jwt");
    let refreshToken = await cookies.get("refresh");
    if (accessToken == undefined && refreshToken == undefined) {
      navigate("/");
    } else if (!accessToken) {
      await fetch(
        `${process.env.REACT_APP_FS_SERVER_BASE_URL}/v0/api/auth/refreshtoken`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin":
              process.env.REACT_APP_FS_SERVER_BASE_URL,
          },
          body: JSON.stringify({ refreshToken: refreshToken }),
        }
      )
        .then(async (response) => {
          const json = await response.json();
          let jwtExpire = new Date();
          jwtExpire.setTime(
            jwtExpire.getTime() + json.allTokens.jwt.expireTime * 1000
          );
          let refreshExpire = new Date();
          refreshExpire.setTime(
            refreshExpire.getTime() + json.allTokens.refresh.expireTime * 1000
          );
          cookies.set("jwt", json.allTokens.jwt.token, {
            expires: jwtExpire,
          });
          cookies.set("refresh", json.allTokens.refresh.token, {
            expires: refreshExpire,
          });
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    isloggedin();

    fetchCmsMetaObjects("cms_data", 4)
      .then((res) => {
        const data = [];
        for (let item of res) {
          try {
            if (item?.fields?.[1]?.key === "name") {
              data.push({
                id: item?.id,
                data: JSON.parse(item?.fields?.[0]?.value),
                name: item?.fields?.[1]?.value,
              });
            }
          } catch (err) {
            console.error("error displaying cms  metaobjects");
          }
        }
        setCmsData(data);
      })
      .catch((err) => console.error("error fetching cms metaobjects"));
    fetchCmsMetaObjects("cms", 4)
      .then((res) => {
        const data = [];
        for (let item of res) {
          try {
            if (item?.fields?.[2]?.key === "name") {
              data.push({
                id: item?.id,
                dataOrder: JSON.parse(item?.fields?.[1]?.value),
                data: JSON.parse(item?.fields?.[0]?.value),
                name: item?.fields?.[2]?.value,
              });
            }
          } catch (err) {
            console.error("error displaying cms  metaobjects");
          }
        }
        setCms(data);
      })
      .catch((err) => console.error("error fetching cms metaobjects"));
    fetchCmsMetaObjects("cms_data_featured_collection", 4)
      .then((res) => {
        const data = [];
        for (let item of res) {
          try {
            if (item?.fields?.[2]?.key === "name") {
              data.push({
                id: item?.id,
                collections: JSON.parse(item?.fields?.[1]?.value),
                collection_order: JSON.parse(item?.fields?.[0]?.value),
                name: item?.fields?.[2]?.value,
              });
            }
          } catch (err) {
            console.error("error displaying cms  metaobjects");
          }
        }
        setCmsDataFeaturedCollection(data);
      })
      .catch((err) => console.error("error fetching cms metaobjects"));
  }, []);

  const handleCmsSelection = ({
    cmsType,
    name,
    data,
    dataOrder,
    id,
    collections,
    collection_order,
  }) => {
    if (cmsType === "cms") {
      setShowCms({ data, dataOrder, id, name, type: cmsType });
      setChangedData(data);
      setChangedDataOrder(dataOrder);
    } else if (cmsType === "cms_data") {
      setShowCms({ data, id, name, type: cmsType });
      setChangedData(data);
    } else if (cmsType === "cms_data_featured_collection") {
      setShowCms({ collections, collection_order, id, name, type: cmsType });
      setChangedCollections(collections);
      setChangedCollectionOrder(collection_order);
    } else {
      setShowCms({
        data: null,
        name: null,
        dataOrder: null,
      });
    }
    setIsChanged(false);
    setErrorMessage({});
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    try {
      const jsObject = JSON.parse(value);
      switch (id) {
        case "data":
          setChangedData(jsObject);
          break;
        case "dataOrder":
          setChangedDataOrder(jsObject);
          break;
        case "collections":
          setChangedCollections(jsObject);
          break;
        case "collection_order":
          setChangedCollectionOrder(jsObject);
          break;
        default:
          break;
      }
      setIsError(false);
      setErrorMessage({ ...errorMessage, [id]: null });
      setIsChanged(true);
    } catch (error) {
      switch (id) {
        case "data":
          setChangedData(value);
          break;
        case "dataOrder":
          setChangedDataOrder(value);
          break;
        case "collections":
          setChangedCollections(value);
          break;
        case "collection_order":
          setChangedCollectionOrder(value);
          break;
        default:
          break;
      }
      setIsError(true);
      setIsChanged(true);
      setErrorMessage({ ...errorMessage, [id]: error?.message });
      console.error(error?.message || "error in JSON");
    }
  };
  const handleCancel = async () => {
    setIsChanged(false);
    switch (showCms?.type) {
      case "cms":
        setChangedData(showCms?.data);
        setChangedDataOrder(showCms?.dataOrder);
        break;
      case "cms_data":
        setChangedData(showCms?.data);
        break;
      case "cms_data_featured_collection":
        setChangedCollections(showCms?.collections);
        setChangedCollectionOrder(showCms?.collection_order);
        break;
      default:
        break;
    }
    setErrorMessage({});
  };
  const handleSave = async () => {
    if (isError) {
      setApiRes({ message: "Error in JSON" });
      return;
    } else {
      setIsLoading(true);
      let name = showCms?.name.replace(/\s/g, "");
      name = name.includes("westernWear")
        ? "westernWear"
        : name.replace(/[0-9]/g, "");
      try {
        let data = {
          id: showCms?.id,
          name: name,
          type: showCms?.type,
          cmsData: {
            data: changedData,
            dataOrder: changedDataOrder,
            collections: changedCollections,
            collection_order: changedCollectionOrder,
          },
        };
        const res = await updateShopifyCms(JSON.stringify(data), 4);
        if (res.status === 200) {
          setIsChanged(false);
        }
        setApiRes(res);
        setIsLoading(false);
      } catch (error) {
        setApiRes({ message: error?.message || "Error while saving" });
        console.error(error?.message || "error in JSON");
      }
    }
  };

  return (
    <Box>
      <Header />
      {(isLoading || apiRes?.message) && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 9999,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {apiRes?.message ? (
              <Box
                sx={{
                  p: 2,
                  backgroundColor: apiRes?.status === 200 ? "green" : "red",
                }}
              >
                <Typography sx={{ color: "#ffffff", fontSize: "20px", mb: 1 }}>
                  {apiRes?.message}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => {
                    setIsLoading(false);
                    setApiRes({});
                  }}
                >
                  Close
                </Button>
              </Box>
            ) : (
              <CircularProgress color="inherit" />
            )}
          </Box>
        </Box>
      )}
      <Box sx={{ mt: 9, mb: 2, mx: { xs: 2, md: 6 } }}></Box>
      <Button onClick={handleCmsSelection} sx={{ mr: 1 }}>
        <a href="/home" style={{ textDecoration: "none" }}>
          HOME
        </a>
      </Button>
      <Button
        onClick={handleCmsSelection}
        variant={!showCms?.name ? "contained" : null}
        sx={{ mr: 1 }}
      >
        CMS JSON
      </Button>
      <Box>
        <Typography variant="h4" sx={{ mx: 1.5, mt: 1.5 }}>
          CMS JSON
        </Typography>
        {cms.map((item, index) => (
          <Button
            key={item?.id}
            onClick={() =>
              handleCmsSelection({
                cmsType: "cms",
                name: item?.name,
                data: item?.data,
                dataOrder: item?.dataOrder,
                id: item?.id,
              })
            }
            variant={showCms.name === item?.name ? "contained" : null}
          >
            {item?.name}
          </Button>
        ))}
      </Box>
      <Box>
        <Typography variant="h4" sx={{ mx: 1.5, mt: 1.5 }}>
          CMS DATA JSON
        </Typography>
        {cmsData.map((item, index) => {
          // remove number from item.name
          let name = item?.name.replace(/[0-9]/g, "");
          if (cmsDataShow.includes(name) || name.includes("westernWear")) {
            return (
              <Button
                key={index}
                onClick={() =>
                  handleCmsSelection({
                    cmsType: "cms_data",
                    name: item?.name,
                    data: item?.data,
                    id: item?.id,
                  })
                }
                variant={showCms.name === item?.name ? "contained" : null}
              >
                {item?.name}
              </Button>
            );
          } else return null;
        })}
      </Box>
      <Box>
        <Typography variant="h4" sx={{ mx: 1.5, mt: 1.5 }}>
          CMS DATA FEATURED COLLECTION JSON
        </Typography>
        {cmsDataFeaturedCollection.map((item, index) => (
          <Button
            key={index}
            onClick={() =>
              handleCmsSelection({
                cmsType: "cms_data_featured_collection",
                name: item?.name,
                collections: item?.collections,
                collection_order: item?.collection_order,
                id: item?.id,
              })
            }
            variant={showCms.name === item?.name ? "contained" : null}
          >
            {item?.name}
          </Button>
        ))}
      </Box>
      <Box sx={{ m: 1.5 }}>
        <Box>
          <Typography variant="h4" sx={{ mb: 2 }}>
            {showCms?.name}
          </Typography>
          <Typography variant="p" sx={{ border: "2px solid", p: 1 }}>
            ID: {showCms?.id}
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={!isChanged || isError}
          >
            Save (DEV environment)
          </Button>
          <Button
            variant="contained"
            sx={{ ml: 2 }}
            onClick={handleCancel}
            disabled={!isChanged}
          >
            Cancel
          </Button>
        </Box>
        {showCms?.dataOrder ? (
          <JsonEditor
            id="dataOrder"
            title="Data Order"
            errorMessage={errorMessage?.dataOrder}
            value={changedDataOrder}
            handleChange={handleChange}
          />
        ) : null}
        {showCms?.data ? (
          <JsonEditor
            id="data"
            title="Data"
            errorMessage={errorMessage?.data}
            value={changedData}
            handleChange={handleChange}
          />
        ) : null}
        {showCms?.collection_order ? (
          <JsonEditor
            id="collection_order"
            title="Collection Order"
            errorMessage={errorMessage?.collection_order}
            value={changedCollectionOrder}
            handleChange={handleChange}
          />
        ) : null}
        {showCms?.collections ? (
          <JsonEditor
            id="collections"
            title="Collections"
            errorMessage={errorMessage?.collections}
            value={changedCollections}
            handleChange={handleChange}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default CmsJson;
